@tailwind base;
@tailwind components;
@tailwind utilities;


  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200&display=swap');

  @layer base{
    body{
      @apply font-[Raleway];
    }
    
    li{
      @apply px-4;
      @apply cursor-pointer;
    }

  }

  .content-div{
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 250px;
  }

